import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default () => {

    return (
        <Layout>
            <div id={'content'} className={'animate-fade-in'}>
                <h1>Willkommen bei der Rechtsanwaltskanzlei Thomas Reiser</h1>
                <p>
                    Für Ihren Erfolg und Ihr Interesse arbeite ich versiert, erfahren, verlässlich und zielstrebig.
                    Als Rechtsanwalt verstehe ich mich als Dienstleister meiner Mandantschaft. Ihre oft sehr
                    persönlichen
                    Anliegen werden mit absoluter Diskretion und Kompetenz behandelt. Die Kombination aus aktuellem
                    Wissen,
                    welches durch stetige Teilnahme an Fortbildungsveranstaltungen gewährleistet ist und dem
                    erforderlichen
                    Feingefühl hat für mich oberste Priorität. Intensive Kommunikation mit meiner Mandantschaft ist für
                    mich
                    unerlässlich.
                </p>
                <h2>
                    Engagiert vertrete ich außergerichtlich und gerichtlich Ihre individuellen Interessen.
                </h2>
                <p>
                    Ich arbeite eng zusammen mit Anwaltskollegen, die Rechtsgebiete außerhalb meiner Spezialisierung
                    bearbeiten sowie mit Notaren und Sachverständigen. In diesem Fall kann ich Ihnen kompetente
                    Ansprechpartner nennen, an die Sie sich wenden können.
                </p>
                <h2>Beratung bei der Rechtsanwaltskanzlei Thomas Reiser</h2>
                <p>
                    Frühe Beratung hilft, überflüssige Prozesse zu verhindern und unvermeidbare Prozesse zu gewinnen.
                    Kontaktieren Sie uns daher zu einem möglichst frühen Zeitpunkt, um schnell, umfassend und
                    erfolgreich
                    für Sie tätig werden zu können.
                </p>
            </div>
        </Layout>
    )
};

export const Head = () => {
    return (<SEO title={'Home'}/>);
}
